body {
    font-family: Helvetica, Arial, sans-serif;
    color: #0F172A;
    padding: 0;
    margin: 0;
}

.d-block{
    display: block;
}
.d-none{
    display: none;
}
.dropdown{
    align-self: center;
    margin-right: 5px;
    position: relative;
    padding: 10px 10px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50px;
    outline: none;
    cursor: pointer;
}
.dropdown:hover{
    background-color: lightgray;
    border: 1px solid lightgray;
    outline: none;
}
.dropdown:focus{
    border: 1px solid lightgray;
    outline: none;
}
.tox-notification__body p{
    margin-left: 4px!important;
}
.tox-notification {
    display: flex!important;
    justify-content: space-between!important;
    padding-left: 10px!important;
}
.tox-progress-bar {
    align-self: center!important;
}
.tox-tinymce {
     border: none!important;
    border-radius: 0!important;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    overflow: hidden;
    position: relative;
    visibility: inherit!important;
}